import { backendAPI } from "./../utils/axios";

const prefix = "/dashboard/";

class DashboardService {
  async findCurrentDate() {
    const response = await backendAPI.get(`${prefix}current-date`);

    return response;
  }

  async findMonthDate(params) {
    const response = await backendAPI.get(`${prefix}month-to-date`, { params });

    return response;
  }
}

export default new DashboardService();
