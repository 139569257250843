<template>
  <v-row justify="center">
    <!-- <v-col cols="12" sm="6" md="6" lg="6">
      <BarChart />
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="6">
      <LineChart />
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="6">
      <ColumnsChart />
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="6">
      <BarChartHorizontal />
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="6">
      <DonutChart />
    </v-col>
    <v-col cols="12" sm="6" md="6" lg="6">
      <PeiChart />
    </v-col> -->
    <v-col cols="12" v-if="permission(roleMenu.dashboard, roleOptions.filter)">
      <v-card>
        <v-card-text class="py-6">
          <v-col cols="12" lg="9" sm="9" md="9">
            <v-form>
              <v-row align="center">
                <v-col cols="4" class="px-2 py-0">
                  <v-dialog
                    ref="startDateDialog"
                    v-model="startDateDialog"
                    :return-value.sync="params.startDate"
                    persistent
                    max-width="350"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="params.startDate"
                        label="Start date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="params.startDate"
                      scrollable
                      locale="th"
                      width="100%"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="startDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateDialog.save(params.startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="4" class="px-2 py-0">
                  <v-dialog
                    ref="endDateDialog"
                    v-model="endDateDialog"
                    :return-value.sync="params.endDate"
                    persistent
                    max-width="350"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="params.endDate"
                        label="End date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="params.endDate"
                      scrollable
                      locale="th"
                      width="100%"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="endDateDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endDateDialog.save(params.endDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn color="purple" block dark @click="search">
                    <v-icon> mdi mdi-magnify</v-icon> ค้นหา</v-btn
                  >
                </v-col>
                <v-col cols="6" lg="auto" md="auto" sm="auto">
                  <v-btn
                    elevation="2"
                    color="purple"
                    block
                    outlined
                    class="mx-1"
                    @click="clear"
                  >
                    <v-icon>mdi-sync</v-icon>
                    เคลียร์
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="permission(roleMenu.dashboard, roleOptions.today)">
      <v-row>
        <v-col
          cols="12"
          v-for="(item, index) in dashboardData"
          :key="index"
          :lg="item.type === 'total' ? '12' : '6'"
        >
          <v-card
            :color="
              item.type !== 'total'
                ? colors[index]
                : item.value >= 0
                ? colors[0]
                : colors[1]
            "
          >
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-xl">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-row align="center">
                <v-col
                  :class="item.type === 'total' ? 'text-6xl' : 'text-4xl'"
                  cols="6"
                >
                  {{ item.value | getFormatCurrency }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import BarChart from "../components/BarChart";
// import LineChart from "../components/LineChart";
// import ColumnsChart from "../components/ColumnChart";
// import BarChartHorizontal from "../components/BarChartHorizontal";
// import DonutChart from "../components/DonutChart";
// import PeiChart from "../components/PeiChart";

import DashBoardService from "./../services/dashboard.service";
import { mapActions } from "vuex";
import { actionPermisstion, roleOptions, roleMenu } from "./../utils/role";

import moment from "moment";

export default {
  name: "Dashbaord",
  data() {
    return {
      dashboardData: [],
      params: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      startDateDialog: false,
      endDateDialog: false,
      colors: [
        "#27ae60",
        "#e74c3c",
        "rgba(41, 128, 185,0.5)",
        "rgba(41, 128, 185,0.8)",
        "#95a5a6",
        "rgba(149, 165, 166,0.6)",
        "rgba(149, 165, 166,1.0)",
        "rgba(149, 165, 166,0.6)",
        "rgba(149, 165, 166,1.0)"
      ],
      roleOptions,
      roleMenu
    };
  },
  methods: {
    ...mapActions({
      alert: "notification/show"
    }),
    async findCurrentDate() {
      try {
        const { data, status } = await DashBoardService.findCurrentDate();
        if (status === 200) {
          this.dashboardData = data;
        }
      } catch (error) {
        this.alert({ type: "error", text: error.message });
      }
    },
    async findMonthDate(options) {
      try {
        const { data, status } = await DashBoardService.findMonthDate(options);
        if (status === 200) {
          this.dashboardData = data;
        }
      } catch (error) {
        this.alert({ type: "error", text: error.message });
      }
    },
    search() {
      this.findMonthDate(this.params);
    },
    clear() {
      this.params = this.$options.data.call(this).params;

      this.findCurrentDate();
    },
    permission(role, action) {
      return actionPermisstion(role, action);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.findCurrentDate();

      console.log(actionPermisstion("dashboard", "filter"));
    });
  },
  components: {
    // BarChart,
    // LineChart,
    // ColumnsChart,
    // BarChartHorizontal,
    // DonutChart,
    // PeiChart
  },

  created() {
    const room = this.$route.query.room;
    this.sockets.subscribe(room, res => {
      alert(res);
    });
  }
};
</script>
